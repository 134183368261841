<template>
    <v-card>
        <v-app-bar flat dark color="primary">
            <v-toolbar-items>
                <v-btn :class="{ darkenPrimary: showModal == 1 ? true : false }" dark text @click.native="showModal = 1">RESERVA</v-btn>
                <v-btn :class="{ darkenPrimary: showModal == 2 ? true : false }" text @click.native="showModal = 2">PAGOS</v-btn>
                <v-btn v-if="user.idBussines == 1" :class="{ darkenPrimary: showModal == 9 ? true : false }" text @click.native="showModal = 9"
                    >CONFIRMACIÓN DE RESERVA</v-btn
                >
                <v-btn v-if="user.idBussines != 1" :class="{ darkenPrimary: showModal == 3 ? true : false }" text @click.native="showModal = 3">ENVÍO</v-btn>
                <v-btn v-if="user.idBussines != 1" :class="{ darkenPrimary: showModal == 4 ? true : false }" text @click.native="showModal = 4">CORREOS</v-btn>
                <v-btn v-if="user.idBussines != 1" :class="{ darkenPrimary: showModal == 5 ? true : false }" text @click.native="showModal = 5">SODIMAC</v-btn>
            </v-toolbar-items>
            <v-menu bottom left v-if="user.idBussines != 1">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="yellow" v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item @click="showModal = 6">
                        <v-list-item-title>ORDEN DE PRODUCCION</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="showModal = 7">
                        <v-list-item-title>ORDEN DE EQUIPAMIENTO</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="showModal = 8">
                        <v-list-item-title>HISTORIAL ESTADO</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="showModal = 10">
                        <v-list-item-title>ORDEN DE SERVICIO</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="downloadActaEntrega">
                        <v-list-item-title>ACTA DE ENTREGA</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="printerReservaSinPrecio">
                        <v-list-item-title>NOTA DE SALIDA</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="showModal = 11">
                        <v-list-item-title>PROCESOS</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-spacer></v-spacer>
            <span class="px-1">Ticket - {{ datos.id }}</span>
            <v-btn v-if="user.idBussines != 1" class="px-1 mx-1" color="blue" :loading="loadingSync" @click.native="contrato(datos)">Generar Contrato</v-btn>
            <v-btn v-if="datos.idColaborador == 333" class="px-1 mx-1" color="green" :loading="loadingSync" @click.native="readyToShip(datos)"
                >Listo para Enviar</v-btn
            >
        </v-app-bar>
        <v-card-text class="mainText">
            <v-container grid-list-md>
                <keep-alive>
                    <component :is="selectComponent"></component>
                </keep-alive>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-btn :class="{ darkenPrimary: !!showModal }" color="white" dark text @click.native="closeModal">CERRAR</v-btn>
            <v-spacer> </v-spacer>
            <v-btn color="primary" v-if="showModal != 4 && showModal != 5 && !modalReserva.showItem" :loading="loading" dark @click="postVenta(0)">
                {{ textButton }}
            </v-btn>

            <v-btn color="secondary" v-if="!modalReserva.showItem" :disabled="showModal == 2 ? false : true" :loading="loading" @click="postVenta(1)"
                >Finalizar Venta</v-btn
            >
        </v-card-actions>
    </v-card>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { mixins } from '@/mixins/mixin.js'
import LazyLoading from '@/components/loading/LazyLoading'
import ListaResumen from '@/components/reservas/ListaResumen'
import DefaultForm from '@/utils/defaultForm'

function lazyComponent(name) {
    return () => ({
        component: import(`@/components/${name}`),
        loading: LazyLoading
    })
}

export default {
    mixins: [mixins],
    $_veeValidate: {
        validator: 'new'
    },
    components: {
        HomeReserva: lazyComponent('reservas/HomeReserva'),
        OrdenProduccion: lazyComponent('reservas/OrdenProduccion'),
        HistorialEstado: lazyComponent('reservas/HistorialEstado'),
        OrdenEquipamiento: lazyComponent('reservas/OrdenEquipamiento'),
        DatosEnvio: lazyComponent('reservas/DatosEnvio'),
        ListaResumen,
        ConfirmacionPedido: lazyComponent('reservas/ConfirmacionPedido'),
        Sodimac: lazyComponent('reservas/Sodimac'),
        ConfirmacionReserva: lazyComponent('reservas/ConfirmacionReserva'),
        OrdenServicio: lazyComponent('reservas/OrdenServicio'),
        Process: lazyComponent('reservas/Process')
    },
    data() {
        return {
            loading: false,
            loadingSync: false,
            showModal: 1 //debe ser 1
        }
    },
    computed: {
        ...mapState('bussines', ['bussines']),
        ...mapState('ventas', ['modalReserva', 'datos']),
        ...mapState('sedes', ['sedes']),
        ...mapGetters('clientes', ['listaClientes']),
        ...mapState('users', ['userLogin', 'roles', 'user']),

        selectComponent() {
            const value = parseInt(this.showModal)
            let val = null
            //console.log('value', value)
            switch (value) {
                case 1:
                    val = 'HomeReserva'
                    break
                case 2:
                    val = 'ListaResumen'
                    break
                case 3:
                    val = 'DatosEnvio'
                    break
                case 4:
                    val = 'ConfirmacionPedido'
                    break
                case 5:
                    val = 'Sodimac'
                    break
                case 6:
                    val = 'OrdenProduccion'
                    break
                case 7:
                    val = 'OrdenEquipamiento'
                    break
                case 8:
                    val = 'HistorialEstado'
                    break
                case 9:
                    val = 'ConfirmacionReserva'
                    break
                case 10:
                    val = 'OrdenServicio'
                    break
                case 11:
                    val = 'Process'
                    break
            }
            return val
        },
        textButton() {
            const { step } = this.modalReserva
            let val = null
            if (step == 1) {
                val = 'AGREGAR VENTA'
            } else if (step == 2) {
                val = 'ACTUALIZAR VENTA'
            }
            return val
        }
    },
    async mounted() {
        const { step } = this.modalReserva
        //console.log('step ', step)
        console.log('this.datos--v1', this.datos)
        if (step == 1) {
            this.$store.commit('ventas/RESET_DATOS')
        }
        ////console.log('mounted', this.datos.id)
        if (this.datos.id) {
            console.log('skip_because_exist')
        } else {
            //console.log('not_exist_and_create')
            await this.$store.dispatch('ventas/postInitVenta')
        }
    },
    methods: {
        async downloadActaEntrega() {
            console.log('this.datos download', this.datos)

            await this.$store.dispatch('reporte/printActoEntrega', this.datos)
        },
        async postVenta(finalizado) {
            this.$validator.validateAll().then(async (result) => {
                let text = ''
                try {
                    if (result) {
                        // ////console.log("update_post_reserva: ");
                        // ////console.log(this.modalReserva);
                        const { id, tarjeta, transferencia, efectivo, importeTotal, idTipoVenta } = this.datos
                        const estado = parseInt(finalizado)
                        const listaRecibido = [efectivo, tarjeta, transferencia]
                        const importeAbonado = this.sumaFloat(listaRecibido)
                        ////console.log(`importeAbonado: ${importeAbonado}== importe total: ${importeTotal}`)

                        if (estado == 0) {
                            // agregar o actualizar
                            //VALIDAR QUE TODA RESERVA SE SEPARE CON ADELANTO - only KUNTE
                            if (this.datos.idTipoVenta === 2 && importeAbonado === 0 && this.bussines.idBussines == 1) {
                                text = 'Toda reserva se adelanta el pago!!!'
                            } else {
                                await this.upReservas(0, id)
                                text = id ? 'Actualizado exitosamente' : 'Agregado exitosamente'
                            }
                        } else {
                            const result = await this.upReservas(1, id)
                            text = 'Finalizado exitosamente'
                            //console.log('###FINALIZADO###', result)
                            this.$store.dispatch('printer/reservation', result)
                        }
                        //console.log(`ruta: ${this.$route.name}`)

                        //console.log('CONTROL_VENTAS_RELOAD')
                        await this.$store.dispatch('ventas/getReservas', {})

                        this.$store.commit('mainUI/OPEN_BAR', {
                            state: true,
                            text,
                            color: 'pink'
                        })
                    }
                } catch (error) {
                    //console.log('err ', error)
                    this.loading = false
                    text = 'Ha ocurrido un error en los datos!'
                }
                this.$store.commit('mainUI/OPEN_BAR', {
                    state: true,
                    text,
                    color: 'red'
                })
            })
        },
        async printerReservaSinPrecio() {
            //console.log('this.datos', this.datos)
            const details = {}
            this.$store.dispatch('printer/reservationSnPrice', this.datos)
        },
        async upReservas(estado, id) {
            //console.log(`estado: ${estado} : id: ${id}`)
            this.loading = true
            ////console.log(`###FINALIZADO: _a_ ${this.datos.finalizado}`);
            let result = null
            if (!id) {
                result = await this.$store.dispatch('ventas/postVenta', { estado })
            } else {
                result = await this.$store.dispatch('ventas/updateReserva', {
                    id,
                    estado
                })
            }
            //console.log(`###FINALIZADO: _b_${this.datos.finalizado}`)
            //await this.$store.dispatch('ventas/lsVentaNoFinalizada')

            this.loading = false
            this.$store.commit('ventas/SHOW_MODAL', {
                step: null,
                state: false
            })
            //console.log('resultado_____', result)
            return result
        },
        closeModal() {
            this.$store.commit('ventas/SHOW_MODAL', { step: 1, state: false })
            // LIMPIAR CACHE DE DATOS

            this.$store.commit('ventas/RESET_DATOS', DefaultForm.formDefault())
        },

        async contrato(item) {
            this.loadingSync = true
            await this.$store.dispatch('reporte/generarContrato', item)
            this.loadingSync = false
        },

        async readyToShip(item) {
            this.loadingSync = true
            await this.$store.dispatch('falabella/shipmentOrder', item.productos)
            this.loadingSync = false
        },
        sumaFloat(lista) {
            let suma = 0
            lista.forEach((e) => {
                suma += parseFloat(e)
            })
            return suma
        }
    }
}
</script>
<style scoped>
.mainText {
    padding: 0px !important;
}
.container {
    min-width: 100%;
}
</style>
